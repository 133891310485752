.header {
  width: 100%;
  height: 70rem;  
  background: var(--color-background-dark);
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center; 
}

.header .header-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header .header-content .title {
  width: 32rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;   
}

.header .header-content .title h1 {
  font: 700 4rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-white-shadow-title);  
}

.header .header-content .header-block {
  width: 80%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;  
}

.header .header-content .header-block h2 {
  font: 600 1.8rem barlow;
  color: var(--color-text-white);
  text-shadow: 1px 1px var(--color-text-white-shadow-title);
  text-align: center; 
}

.header .header-content button {
  width: 22rem;
  height: 4rem;
  background: var(--color-button);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  font: 600 1.8rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.header .header-content button:hover {
  color: var(--color-button-text-hover);
}

.header .header-content a {
  text-decoration: none;
}

@media (min-width: 600px) {
  .header {   
    height: 65rem;    
  }
  
  .header .header-content {    
    align-items: flex-start;
  }

  .header .header-content .title {
    width: 58rem;
    height: 18rem;
    justify-content: space-evenly;    
    align-items: flex-start;
    margin-left: 3rem;     
  }

  .header .header-content .title h1 {
    font: 700 5rem barlow;
    color: var(--color-text-white);
    text-shadow: 2px 2px var(--color-text-white-shadow-title);     
  }

  .header .header-content .header-block {
    width: 58rem;
    height: 20rem;    
    align-items: flex-start;
    margin-left: 3rem;
  }

  .header .header-content .header-block h2 {
    font: 600 2.1rem barlow;
    color: var(--color-text-white);
    text-shadow: 1px 1px var(--color-text-white-shadow-title);
    text-align: left;
    margin-bottom: 0.8rem;
  }

  .header .header-content button {
    margin-left: 3rem;
  }
}

@media (min-width: 1200px) {
  .header {   
    height: 75rem;    
  }
  
  .header .header-content .title {
    width: 100rem;
    height: 30rem;
    justify-content: space-evenly;    
    align-items: flex-start;
    margin-left: 8rem;     
  }

  .header .header-content .title h1 {
    font: 700 9rem barlow;
    color: var(--color-text-white);
    text-shadow: 4px 4px var(--color-text-white-shadow-dark);     
  }

  .header .header-content .header-block {
    width: 100rem;
    height: 20rem;    
    align-items: flex-start;
    margin-left: 8rem;
  }

  .header .header-content .header-block h2 {
    font: 600 3.5rem barlow;
    color: var(--color-text-white);
    text-shadow: 1px 1px var(--color-text-white-shadow-title);
    text-align: left;
    margin-bottom: 0.8rem;
  }

  .header .header-content button {
    width: 33rem;
    height: 6rem;
    font: 600 2.2rem barlow;
    margin-left: 8rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}
