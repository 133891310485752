.buy-now {
  width: 100%;
  height: 70rem;  
  background: var(--color-background-dark);  
}

.buy-now .buy-now-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-background-dark);
}

.buy-now .buy-now-content .text-block {
  width: 90%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.buy-now .buy-now-content .text-block .buy-now-text {
  width: 22rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; 
}

.buy-now .buy-now-content .text-block .buy-now-text p {
  font: 500 1.8rem barlow;
  color: var(--color-text);
  text-shadow: 1px 1px var(--color-text-shadow);
}

.buy-now .buy-now-content .text-block strong {
  font: 500 2rem barlow;
  color:  var(--color-text-white);
  text-shadow: 1px 1px var(--color-text-white-shadow-light);
  margin-top: 1rem;
}

.buy-now .buy-now-content .text-block button {
  width: 22rem;
  height: 4rem;
  background: var(--color-button);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  font: 600 1.8rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;
  margin-top: 3rem;  
}

.buy-now .buy-now-content .text-block button:hover {
  color: var(--color-button-text-hover);
}

.buy-now .buy-now-content .text-block a {
  text-decoration: none;
}

.buy-now .buy-now-content .image-block {
  width: 90%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; 
}

.buy-now .buy-now-content .image-block .buy-now-image {
  width: 26.57rem;
  height: 38rem;
}

.buy-now .buy-now-content .image-block .buy-now-image img {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) {
  .buy-now {  
    height: 80rem;   
  }

  .buy-now .buy-now-content .text-block {
    height: 35rem;
  }

  .buy-now .buy-now-content .text-block .buy-now-text {
    width: 30rem;
    height: 18rem;     
  }
  
  .buy-now .buy-now-content .text-block .buy-now-text p {
    font: 500 2.5rem barlow;   
  }
  
  .buy-now .buy-now-content .text-block strong {
    font: 500 2.2rem barlow;   
  }
  
  .buy-now .buy-now-content .text-block button {
    width: 20rem;
    height: 3.5rem;   
    font: 600 1.8rem barlow;   
  }

  .buy-now .buy-now-content .image-block {
    height: 45rem;
  }

  .buy-now .buy-now-content .image-block .buy-now-image {
    width: 29.37rem;
    height: 42rem;
  }
}

@media (min-width: 1200px) {
  .buy-now {    
    height: 70rem;    
  }
  
  .buy-now .buy-now-content {   
    flex-direction: row;
    justify-content: space-between;  
  }

  .buy-now .buy-now-content .text-block {
    width: 42%;
    height: 100%;
    align-items: flex-start;
  }

  .buy-now .buy-now-content .text-block .buy-now-text {
    width: 40rem;
    height: 25rem;     
  }
  
  .buy-now .buy-now-content .text-block .buy-now-text p {
    font: 500 3rem barlow;
    text-shadow: 2px 2px var(--color-text-shadow); 
  }
  
  .buy-now .buy-now-content .text-block strong {
    font: 500 2.5rem barlow;   
  }
  
  .buy-now .buy-now-content .text-block button {
    width: 25rem;
    height: 4rem;   
    font: 600 1.8rem barlow;
    margin-top: 4rem;   
  }

  .buy-now .buy-now-content .image-block {
    width: 45%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;    
  }

  .buy-now .buy-now-content .image-block .buy-now-image {
    width: 36.36rem;
    height: 52rem;
  }
}
