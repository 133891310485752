.description {
  width: 100%;
  height: 60rem;  
  background: var(--color-background-dark); 
}

.description .description-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-background-dark);
}

.description .description-content .description-text {
  width: 90%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.description .description-content .description-text p {
  font: 500 1.3rem barlow;
  color: var(--color-text-white);
  margin-top: 0.3rem;
}

.description .description-content strong {
  font: 600 1.8rem barlow;
  color: var(--color-text);
  text-shadow: 1px 1px var(--color-text-shadow);  
}

.description .description-content .description-list {
  width: 90%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  
}

.description .description-content .description-list li {
  font: 400 1.2rem barlow;
  color: var(--color-text-white);
  text-shadow: 0.5px 0.5px var(--color-text-white-shadow-light);
  margin-left: 2rem;
}

@media (min-width: 600px) {
  .description {
    height: 75rem;
  }

  .description .description-content .description-text {
    width: 58rem;
    height: 22rem;   
  }
  
  .description .description-content .description-text p {
    font: 500 2rem barlow;   
  }

  .description .description-content strong {
    font: 600 2.5rem barlow;
    margin-top: 1rem;
    margin-bottom: 1rem;  
  }

  .description .description-content .description-list {
    width: 58rem;
    height: 30rem;    
  }
  
  .description .description-content .description-list li {
    font: 400 1.9rem barlow;   
    margin-left: 4rem;
  }
}

@media (min-width: 1200px) {
  .description {
    height: 85rem;
  }
  
  .description .description-content {
   justify-content: space-evenly;   
  }

  .description .description-content .description-text {
    width: 90rem;
    height: 30rem;   
  }
  
  .description .description-content .description-text p {
    font: 500 2.5rem barlow;   
  }

  .description .description-content strong {
    font: 600 3rem barlow;    
  }

  .description .description-content .description-list {
    width: 90rem;
    height: 40rem;    
  }
  
  .description .description-content .description-list li {
    font: 400 2.5rem barlow;
    text-shadow: 1px 1px var(--color-text-white-shadow-light);   
    margin-left: 4rem;
  }
}