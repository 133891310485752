 @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-size: 60%;
    --color-background: #F5E9DB;
    --color-background-dark: #191919;
    --color-text-white: #FFFFFF;
    --color-text-white-shadow-dark: #2d2d2d;
    --color-text-white-shadow-title: #636363;
    --color-text-white-shadow-light: #c9c9c9; 
    --color-text: #FFBD59; 
    --color-text-shadow: #936530;   
    --color-text-dark: #191919;
    --color-text-dark-shadow: #636363;        
    --color-button: #FFFFFF;
    --color-button-text: #191919;
    --color-button-text-hover: #444444;        
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background-dark);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem barlow;
    color: var(--color-text-white);
}

.container {
    width: 90vw;    
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}