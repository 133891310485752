.ebook {
  width: 100%;
  height: 175rem;  
  background: var(--color-background); 
}

.ebook .ebook-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-background);
}

.ebook .ebook-content .ebook-text {
  width: 30rem;
  height: 120rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebook .ebook-content .ebook-text h2 {
  font: 600 2.2rem barlow;
  color: var(--color-text-dark);
  text-shadow: 1px 1px var(--color-text-dark-shadow);
  margin-bottom: 0.2; 
}

.ebook .ebook-content .ebook-text strong {
  font: 600 1.8rem barlow;
  color: var(--color-text-dark);
  text-shadow: 1px 1px var(--color-text-dark-shadow);
  margin-bottom: 2rem;
}

.ebook .ebook-content .ebook-text .text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebook .ebook-content .ebook-text .text-block p {
  font: 600 1.5rem barlow;
  color: var(--color-text-dark);
  text-align: center;
  margin-bottom: 0.5rem;
}

.ebook .ebook-content .ebook-image {
  width: 90%;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.ebook .ebook-content .ebook-image strong {
  font: 600 2.2rem barlow;
  color: var(--color-text-dark);
  text-shadow: 1px 1px var(--color-text-dark-shadow);  
  margin-bottom: 1rem;
}

.ebook .ebook-content .ebook-image .capa {
  width: 30rem;
  height: 44.11rem;
}

.ebook .ebook-content .ebook-image .capa img {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) {
  .ebook {    
    height: 135rem;     
  }
  
  .ebook .ebook-content .ebook-text {
    width: 50rem;
    height: 80rem;    
  }

  .ebook .ebook-content .ebook-image {
    width: 50rem;
    height: 50rem;   
  }
}

@media (min-width: 1200px) {
  .ebook {    
    height: 90rem;     
  }

  .ebook .ebook-content {
    flex-direction: row;       
  }
  
  .ebook .ebook-content .ebook-text {
    width: 55rem;
    height: 90rem;
    margin-right: 2rem;    
  }

  .ebook .ebook-content .ebook-text h2 {
    font: 600 2.5rem barlow;
    color: var(--color-text-dark);
    text-shadow: 1px 1px var(--color-text-dark-shadow);
    margin-bottom: 0.2; 
  }
  
  .ebook .ebook-content .ebook-text strong {
    font: 600 2rem barlow;
    color: var(--color-text-dark);
    text-shadow: 1px 1px var(--color-text-dark-shadow);
    margin-bottom: 2rem;
  }

  .ebook .ebook-content .ebook-text .text-block p {
    font: 600 1.6rem barlow;
    margin-bottom: 0.8rem;
  }

  .ebook .ebook-content .ebook-image {
    width: 55rem;
    height: 90rem;
    margin-left: 2rem;  
  }

  .ebook .ebook-content .ebook-image strong {
    font: 600 2.5rem barlow;   
  }

  .ebook .ebook-content .ebook-image .capa {
    width: 45rem;
    height: 66.17rem;
  }
}
